import { Box, Container, Divider, Stack } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { VirtuosoHandle } from "react-virtuoso";
import { Polyline } from "react-leaflet";
import { useGetPipelineInstanceQuery } from "../../api/processApi";
import { useMaskMapDisplay } from "../../hooks/useMask";
import DraggableLayout from "../../../common/components/layout/DraggableLayout";
import { DetectionsDisplay, GeomDisplay } from "./DataDisplay";
import { PipelineModel } from "../../types/DataTypes";
import { useLines } from "../../hooks/useGeoJsonResponse";
import {
    BreadcrumbsRender,
    ExecutionTitleRender,
    ResultsMap,
} from "../../views/ResultsViewer";
import { GeometryList } from "./GeometryLists";
import WktLayer from "../../../common/utils/WktLayer";

const StreetsBboxViewer = ({ pipelineInstanceId, model }: { pipelineInstanceId: string; model: PipelineModel; }) => {
    const { data: pipelineInstance } = useGetPipelineInstanceQuery(pipelineInstanceId);

    const { geometries, downloadGeoJson } = useLines(pipelineInstance?.output_data?.roads?.id);

    const map = useRef<L.Map>(null);
    const updateMapSize = useCallback(() => {
        map.current && map.current.invalidateSize(true);
    }, [map]);

    const { mask, showMask, setShowMask } = useMaskMapDisplay(
        pipelineInstance?.input_data?.bbox_geom,
        map
    );
    const [showDetections, setShowDetections] = useState(true);

    const virtuoso = useRef<VirtuosoHandle>(null);
    const scrollToIndex = useCallback(
        (index: number) => {
            virtuoso.current &&
                virtuoso.current.scrollToIndex({
                    index: index,
                    align: "start",
                    behavior: "smooth",
                });
        },
        [virtuoso]
    );

    return (
        <DraggableLayout
            topLeft={
                <Box
                    sx={{
                        height: 1,
                        width: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Stack
                        direction={"column"}
                        sx={{ paddingX: 5, paddingY: 2 }}
                        spacing={3}
                    >
                        <BreadcrumbsRender
                            model={model}
                            instanceId={pipelineInstanceId}
                        />
                    </Stack>
                    <Container>
                        <Stack
                            paddingY={2}
                            divider={<Divider variant="middle" flexItem />}
                            spacing={1}
                        >
                            <ExecutionTitleRender
                                pipelineInstance={pipelineInstance}
                            />
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                            >
                                <GeomDisplay showMask={showMask} setShowMask={setShowMask} />
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                            >
                                <DetectionsDisplay
                                    show={showDetections}
                                    setShow={setShowDetections}
                                    onClick={() =>
                                        downloadGeoJson({
                                            name: pipelineInstance?.name,
                                        })
                                    }
                                />
                            </Stack>
                        </Stack>
                    </Container>
                    <Divider flexItem />
                    <GeometryList
                        geometries={geometries}
                        map={map}
                        virtuoso={virtuoso}
                    />
                </Box>
            }
            downRight={
                <ResultsMap map={map}>
                    {showDetections &&
                        geometries &&
                        geometries.map((geometry, index) => (
                            <Polyline
                                positions={geometry.vertexs}
                                key={index}
                                eventHandlers={{
                                    click: () => scrollToIndex(index),
                                }}
                            />
                        ))}
                    {showMask && mask && (<WktLayer wktData={mask} pathOptions={{ color: "red", fillOpacity: 0 }} /> )}
                </ResultsMap>
            }
            onDragEnd={updateMapSize}
            onChangeOrientation={updateMapSize}
        />
    );
};

export default StreetsBboxViewer;
