import { latLngBounds, LatLngBounds, LatLngBoundsExpression } from "leaflet";
import Compare from "./Compare";
import RovisenLayer from "../layers/RovisenLayer";
import SentinelLayer from "../layers/SentinelLayer";


export const SentinelView = ({
    date,
    sentinel = true,
    bounds,
}: {
    date: string | undefined;
    sentinel?: boolean;
    bounds?: LatLngBoundsExpression;
}) => {
    return (
        <>
            {date && !sentinel && (
                <RovisenLayer
                    name="rovisen:sentinel_cogs"
                    layerStyle="raster"
                    params={{ TIME: date }}
                    bounds={bounds}
                />
            )}
            {date && sentinel && (
                <SentinelLayer
                    initDate={
                        date.includes("/")
                            ? new Date(date.split("/")[0])
                            : new Date(date)
                    }
                    endDate={
                        date.includes("/")
                            ? new Date(date.split("/")[1])
                            : undefined
                    }
                    bounds={bounds}
                    key={
                        bounds
                            ? bounds instanceof LatLngBounds
                                ? bounds.toBBoxString()
                                : latLngBounds(bounds).toBBoxString()
                            : undefined
                    }
                />
            )}
        </>
    );
}


const CompareSentinel = ({
    init,
    end,
    sentinel = true,
    bounds,
}: {
    init: string | undefined;
    end: string | undefined;
    sentinel?: boolean;
    bounds?: LatLngBoundsExpression;
}) => {
    return (
        <Compare
            leftElements={
                <SentinelView
                    date={init}
                    sentinel={sentinel}
                    bounds={bounds}
                />
            }
            rightElements={
                <SentinelView
                    date={end}
                    sentinel={sentinel}
                    bounds={bounds}
                />
            }
        />
    );
};

export default CompareSentinel;