import { Box, Container, Divider, Stack, TextField, Typography } from "@mui/material"
import { useCallback, useMemo, useRef, useState } from "react"
import { VirtuosoHandle } from "react-virtuoso"
import { Polygon } from "react-leaflet"
import { useGetPipelineInstanceQuery } from "../../api/processApi"
import { useMaskMapDisplay } from "../../hooks/useMask"
import DraggableLayout from "../../../common/components/layout/DraggableLayout"
import { DetectionsDisplay, GeomDisplay } from "./DataDisplay"
import { PipelineModel } from "../../types/DataTypes"
import { usePolygons } from "../../hooks/useGeoJsonResponse"
import { BreadcrumbsRender, ExecutionTitleRender, ResultsMap } from "../../views/ResultsViewer"
import { GeometryList } from "./GeometryLists"
import WktLayer from "../../../common/utils/WktLayer"
import { SentinelView } from "../../../common/components/map/tools/CompareSentinel"
import { wktToBounds } from "../../../common/utils/tools"

const dateFormat = (date: string) => {
    return new Date(date).toISOString().split("T")[0];
};

const outputDict = {
    wildfire_scar_detection_sentinel: 'fireseg_geojson',
}


const DisasterSentinelViewer = ({ pipelineInstanceId, model }: { pipelineInstanceId: string, model: PipelineModel }) => {
    const { data: pipelineInstance } = useGetPipelineInstanceQuery(pipelineInstanceId)

    const outputKey = outputDict[model.name as keyof typeof outputDict]
    const { geometries, downloadGeoJson } = usePolygons(pipelineInstance?.output_data?.[outputKey]?.id)

    const map = useRef<L.Map>(null)
    const updateMapSize = useCallback(() => {
        map.current && map.current.invalidateSize(true)
    }, [map])

    const { mask, showMask, setShowMask } = useMaskMapDisplay(
            pipelineInstance?.input_data?.mask,
            map
        );

    const { date } = useMemo(() => {
        const value = pipelineInstance?.input_data?.date?.payload?.value;
        if (value) {
            return {
                date: dateFormat(value)
            };
        }
        return { date: undefined };
    }, [pipelineInstance]);

    const { TQuery } = useMemo(() => {
        const dates = pipelineInstance?.output_data?.dates;

        if (dates) {
            const tmin = dateFormat(dates?.t_min_date);
            const tmax = dateFormat(dates?.t_max_date);

            const date = tmin !== tmax ? `${tmin}/${tmax}` : tmin;

            return { TQuery: date };
        }
        return { TQuery: undefined };
    }, [pipelineInstance]);

    const [showDetections, setShowDetections] = useState(true)

    const virtuoso = useRef<VirtuosoHandle>(null)
    const scrollToIndex = useCallback((index: number) => {
        virtuoso.current && virtuoso.current.scrollToIndex({
            index: index,
            align: 'start',
            behavior: 'smooth',
        })
    }, [virtuoso])


    return <DraggableLayout
        topLeft={
            <Box sx={{ height: 1, width: 1, display: 'flex', flexDirection: "column" }}>
                <Stack direction={'column'} sx={{ paddingX: 5, paddingY: 2 }} spacing={3}>
                    <BreadcrumbsRender model={model} instanceId={pipelineInstanceId} />
                </Stack>
                <Container>
                    <Stack paddingY={2} divider={<Divider variant="middle" flexItem />} spacing={1}>
                        <ExecutionTitleRender pipelineInstance={pipelineInstance} />
                        <Stack direction="row" spacing={1} alignItems="center">
                            <>
                                <Typography flexGrow={1}>Fecha de busqueda</Typography>
                                <TextField
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    label={"Fecha"}
                                    value={date}
                                    focused
                                    />
                            </>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <GeomDisplay
                                showMask={showMask}
                                setShowMask={setShowMask}
                            />
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <DetectionsDisplay show={showDetections} setShow={setShowDetections} onClick={() => downloadGeoJson({name: pipelineInstance?.name})} />
                        </Stack>
                    </Stack>
                </Container>
                <Divider flexItem />
                <GeometryList geometries={geometries} map={map} virtuoso={virtuoso} />
            </Box>
        }
        downRight={
            <ResultsMap map={map} >
                {showDetections && geometries && geometries.map((geometry, index) => <Polygon positions={geometry.vertexs} key={index} eventHandlers={{ click: () => scrollToIndex(index) }} />)}
                {showMask && mask && (
                    <WktLayer
                        wktData={mask}
                        pathOptions={{ color: "red", fillOpacity: 0 }}
                    />
                )}
                <SentinelView
                    date={TQuery}
                    bounds={mask ? wktToBounds(mask) : undefined}
                />
            </ResultsMap>
        }
        onDragEnd={updateMapSize}
        onChangeOrientation={updateMapSize}
    />
}

export default DisasterSentinelViewer
