import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { useGetPipelineInstanceQuery, useGetPipelineModelQuery } from "../api/processApi"
import { AppDispatch, RootState } from "../../app/state/store"
import { RefObject, useCallback, useMemo, useRef } from "react"
import DraggableLayout from "../../common/components/layout/DraggableLayout"
import { Box, Breadcrumbs, CircularProgress, Container, Divider, IconButton, Stack, Typography } from "@mui/material"
import { AddRoad, Close, RemoveRoad } from "@mui/icons-material"
import BaseMap from "../../common/components/map/BaseMap"
import { ZoomControl } from "react-leaflet"
import BaseMapControls, { ControlGroup } from "../../common/components/map/layout/BaseMapControls"
import LocationUtilities from "../../common/components/map/control/LocationUtilities"
import DrawingUtilities from "../../common/components/map/control/DrawingUtilities"
import BaseMapSelector from "../../mainMap/components/control/BaseMapSelector"
import BaseMapToogleButton from "../../common/components/UI/general/ToggleButton"
import { setStreets } from "../../mainMap/state/baseMapSlice"
import { MapLibreTileLayer } from "../../common/utils/MapLibreTileLayer"
import { PipelineInstance, PipelineModel } from "../types/DataTypes"
import CenteredCircularProgress from "../../common/components/UI/general/CenteredCircularProgress"
import ChangeViewer from "../components/resultViewer/ChangeViewer"
import StreetsViewer from "../components/resultViewer/StreetsViewer"
import BuildingsViewer from "../components/resultViewer/BuildingsViewer";
import { LandViewer } from "../components/resultViewer/LandViewer"
import NotFound from "../../app/views/NotFound"
import BlocksViewer from "../components/resultViewer/BlocksViewer"
import { DensityEstimationViewer } from "../components/resultViewer/DensityEstimationViewer"
import { WildfirePredictionViewer } from "../components/resultViewer/WildfirePredictionViewer"
import { WildfireHistoricViewer } from "../components/resultViewer/WildfireHistoricViewer"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import ImpactAssessmentViewer from "../components/resultViewer/ImpactAssessmentViewer"
import DisasterViewer from "../components/resultViewer/DisasterViewer"
import DisasterSentinelViewer from "../components/resultViewer/DisasterSentinelViewer"
import BlocksBboxViewer from "../components/resultViewer/BlocksBboxViewer"
import BuildingsBboxViewer from "../components/resultViewer/BuildingsBboxViewer"
import StreetsBboxViewer from "../components/resultViewer/StreetsBboxViewer"


export const BreadcrumbsRender = ({ model, instanceId }: { model: PipelineModel, instanceId: string }) => {
    return <Breadcrumbs aria-label="breadcrumb" separator=">">
        <Link to="/processes">Procesos</Link>
        <Link to={`/processes/${model.id}`}>{model.subtitle ? `${model.title}:${model.subtitle}` : model.title}</Link>
        <Link to={`/processes/${model.id}/instance/${instanceId}`}>{`Ejecución: ${instanceId}`}</Link>
        <Typography color="textPrimary">Resultados</Typography>
    </Breadcrumbs>
}

export const ExecutionTitleRender = ({ pipelineInstance }: { pipelineInstance?: PipelineInstance }) => {
    return <Box>
        <Stack spacing={1} alignItems="center" direction="row">
            <Typography variant="h6">Ejecución:</Typography>
            {pipelineInstance
                ? <Typography variant="h6" flexGrow={1}>{pipelineInstance.name}</Typography>
                : <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                    <CircularProgress />
                </Box>
            }
        </Stack>
        <Typography variant="subtitle1" color="gray">{`id: ${pipelineInstance?.id}`}</Typography>
    </Box>
}

export const InputsRender = () => {
    return <Stack spacing={1} alignItems="center" direction="row">
        <Typography variant="h6">inputs:</Typography>
    </Stack>
}

export const OutputsRender = () => {
    return <Stack spacing={1} alignItems="center" direction="row">
        <Typography variant="h6">outputs:</Typography>
    </Stack>
}

export const PropertiesRender = ({ feature, setFeature }: { feature: any, setFeature: any }) => {
    const properties = feature?.properties
        ? Object.entries(feature.properties).map(([key, value]: any) => ({ id: key, value: value }))
        : []

    return <>
        {
            properties.length > 0 &&
            <Box margin={3}>
            <Stack spacing={1} alignItems="left">
                <Stack direction="row" alignItems='center' spacing={1}>
                    <Typography variant="h6" flexGrow={1}>Propiedades:</Typography>
                    <IconButton size="small" onClick={() => setFeature({})}>
                        <Close />
                    </IconButton>
                </Stack>
                <PropertiesDataGrid properties={properties} />
            </Stack>
        </Box>
        }
    </>
}

export const PropertiesDataGrid = ({ properties }: {properties: {id: string, value: any}[]}) => {
    const columns: GridColDef<{id: string, value: any}>[] = useMemo(() => ([
        {
            field: 'id',
            headerName: 'Propiedad',
            flex: 1,
            valueFormatter: (value, row) => {
                if (row.id === 'density_mean') return 'Densidad promedio'
                else if (row.id === 'semantic_mode') return 'Clase dominante'
                else return value
            }
        },
        {
            field: 'value',
            headerName: 'Valor',
            flex: 1,
            valueFormatter: (value, row) => {
                if (row.id === 'density_mean') {
                    const mean = row.value/255
                    return `${mean.toFixed(2)} hab/m²`
                } else if (row.id === 'density_min') {
                    const min = row.value/255
                    return `${min.toFixed(2)} hab/m²`
                } else if (row.id === 'density_max') {
                    const max = row.value/255
                    return `${max.toFixed(2)} hab/m²`
                } else if (row.id === 'semantic_mode') {
                    return row.value === 0 ? 'Bosque' :
                    row.value === 1 ? 'Agricultural' :
                    row.value === 2 ? 'Urbano' :
                    row.value === 3 ? 'Comercial/Industrial' :
                    row.value === 4 ? 'Desierto' :
                    row.value === 5 ? 'Montaña' :
                    row.value === 6 ? 'Cuerpo de agua' :
                    row.value === 7 ? 'Otra Naturaleza' :
                    row.value === 8 ? 'Sin Etiqueta' : 'N/A'
                }
                else return row.value.toFixed ? row.value.toFixed(2) : row.value
            }
        }
    ]), [])

    return <DataGrid
        rows={properties}
        columns={columns}
        autoHeight
    />
}

export const ResultsMap = ({ map, children }: { map: RefObject<L.Map>, children?: any }) => {
    const baseMapConfig = useSelector((state: RootState) => state.baseMap)
    const dispatch = useDispatch<AppDispatch>()

    return <BaseMap baseMap={baseMapConfig.baseMap} innerRef={map} zoomControl={false} doubleClickZoom={false} maxZoom={22}>
        {children}
        <ZoomControl position="topleft" />
        <BaseMapControls>
            <LocationUtilities />
            <DrawingUtilities />
            <ControlGroup>
                <BaseMapSelector />
                <Divider orientation='vertical' variant='middle' flexItem />
                <BaseMapToogleButton
                    icon={<AddRoad fontSize='inherit' />}
                    selectedIcon={<RemoveRoad fontSize='inherit' />}
                    tooltip={'Show streets'}
                    selectedTooltip={'HideStreets'}
                    selected={baseMapConfig.streets}
                    enabled={baseMapConfig.baseMap !== 'osm'}
                    onClick={() => dispatch(setStreets(!baseMapConfig.streets))}
                />
            </ControlGroup>
        </BaseMapControls>
        {baseMapConfig.streets && <MapLibreTileLayer url="https://api.maptiler.com/maps/29b43753-8fe0-4881-b56b-91b28b4f694c/style.json?key=dCFtNpk6lDWiGmtbFNYs" attribution="" />}
    </BaseMap>
}


// TODO: use this one eventualy
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ResultsRenderer = ({ instanceId, model }: { instanceId: string, model: PipelineModel }) => {
    const { data: pipelineInstance } = useGetPipelineInstanceQuery(instanceId)

    const map = useRef<L.Map>(null)
    const updateMapSize = useCallback(() => {
        map.current && map.current.invalidateSize(true)
    }, [map])


    return <DraggableLayout
        topLeft={
            <Box sx={{ height: 1, width: 1, display: 'flex', flexDirection: "column" }}>
                <Stack direction={'column'} sx={{ paddingX: 5, paddingY: 2 }} spacing={3}>
                    <BreadcrumbsRender model={model} instanceId={instanceId} />
                </Stack>
                <Container>
                    <Stack paddingY={2} divider={<Divider variant="middle" flexItem />} spacing={1}>
                        <ExecutionTitleRender pipelineInstance={pipelineInstance} />
                        <InputsRender />
                        <OutputsRender />
                    </Stack>
                </Container>
                <Divider flexItem />
                {/* <GeometryList /> */}
            </Box>
        }
        downRight={
            <ResultsMap map={map} >
            </ResultsMap>
        }
        onDragEnd={updateMapSize}
        onChangeOrientation={updateMapSize}
    />
}

const ResultsRenderer2 = ({ instanceId, model }: { instanceId: string, model: PipelineModel }) => {
    const viewerComponents = {
        block_detection: BlocksViewer,
        block_detection_bbox: BlocksBboxViewer,
        building_detection: BuildingsViewer,
        building_detection_bbox: BuildingsBboxViewer,
        change_detection: ChangeViewer,
        density_estimation: DensityEstimationViewer,
        flood_detection: DisasterViewer,
        tsunami_scar_detection: DisasterViewer,
        historic_wildfire: WildfireHistoricViewer,
        impact_assessment: ImpactAssessmentViewer,
        semantic_segmentation: LandViewer,
        street_detection: StreetsViewer,
        street_detection_bbox: StreetsBboxViewer,
        urban_change_analysis: ChangeViewer,
        wildfire_prediction: WildfirePredictionViewer,
        wildfire_scar_detection: DisasterViewer,
        wildfire_scar_detection_sentinel: DisasterSentinelViewer,
    }

    const ViewerComponent = viewerComponents[model.name as keyof typeof viewerComponents] || NotFound;

    return <ViewerComponent pipelineInstanceId={instanceId} model={model} />
};

const ModelGetter = ({ modelId, instanceId }: { modelId: string, instanceId: string }) => {
    const { data: model, isLoading, isSuccess } = useGetPipelineModelQuery(modelId);

    return isLoading
        ? <CenteredCircularProgress />
        : isSuccess && model
            ? <ResultsRenderer2 instanceId={instanceId} model={model} />
            : null
}


const ResultsViewer = () => {
    const { modelId, instanceId } = useParams();
    return modelId && instanceId ? <ModelGetter modelId={modelId} instanceId={instanceId} /> : <NotFound />;
};


export default ResultsViewer
